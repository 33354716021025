const Footer = () => {
    return (

        <div>
            <section className="footer-section">

                <div className="box1_footer">
                    <h1 className="footer-text">creating design<br></br>that feels natural.</h1>
                </div>




                <div className="box2_footer">
                    <h1 className="footer-text-link bottom-lg">Linkedin</h1>
                    <h1 className="footer-text-link bottom-lg">Resume</h1>
                    <h1 className="footer-text-link bottom-lg">About me</h1>
                    <h1 className="footer-text-link bottom-lg">Whatsapp</h1>
                </div>



                <div className="box3_footer">
                    <h2 className="bottom-xl footer-text">junaid79x@gmail.com</h2>
                    <button>Copy email</button>
                </div>




            </section>
            <div className="line-border"></div>

            <div className="lowerbox">

              
                <p className="sm">2024</p>

                <p className="sm">designed in dubai with peer pressure</p>

                <p className="sm">time @uae: 12.34pm</p>
            </div>

        </div>
    );
}

export default Footer;